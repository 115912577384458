import React, {useEffect, useRef, useState} from "react";
import MeasureList_get from "../../http/GET/MeasureList_get";
import Download_pcg_get from "../../http/GET/Download_pcg_get";
import Download_ppg_get from "../../http/GET/Download_ppg_get";
import 'bootstrap/dist/css/bootstrap.min.css';
import UserList_pagenation from "../UserList/UserList_pagenation";
import {useOutletContext} from "react-router";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import F_Graph from "./chart/Graph";
import User_data_get from "../../http/GET/User_data_get";
import { GrPowerReset } from "react-icons/gr";


function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const F_Measure_page = () => {

    const {id} = useParams();
    const setModalOpen = useOutletContext().setModalOpen;
    const [name, setName] = useState("");

    const page_number = parseInt(useQuery().get("page"));
    const [reset_count, setReset_count] = useState(0);
    const [now_page, setNow_page] = useState(page_number-1);
    const [totalPages, setTotalPages] = useState(1);


    const [hearts_data, setHearts_data] = useState([]);
    const [hearts_graph, setHearts_graph] = useState({});
    const navigate = useNavigate();

    const [chart_data, setChart_data] = useState({});
    const [onoff, setonoff] = useState(false);


    const [graphStatus, setGraphStatus] = useState('');


    const change_page = (data)=>{
        const pathname = window.location.pathname;
        setNow_page(data);
        navigate(`${pathname}?page=${data+1}`);
    }


    const handleGraphStatusChange = (event) => {
        setGraphStatus(event.target.value);
    };

    const back_color = {
        backgroundColor: "#F5F5F5",
    };

    const handleClick_pcg = async (event, heart_data) => {
        event.preventDefault(); // 기본 a 태그 동작을 막음
        const result = await Download_pcg_get(heart_data.measureId);
        //console.log(result);

        if (result === 1 || result === 500) {
            alert("데이터 없음");
        } else if (result === 0) {
            setModalOpen(true);
        } else {
            window.location.href = `https://admin.clairaudience.co.kr/api/engineer/v1/synesper-lite-family/measure/pcg/${heart_data.measureId}`;
        }
    };

    const handleClick_ppg = async (event, heart_data) => {
        event.preventDefault(); // 기본 a 태그 동작을 막음

        const result = await Download_ppg_get(heart_data.measureId);

        if (result === 1) {
            alert("데이터 없음");
        } else if (result === 0) {
            setModalOpen(true);
        } else {
            window.location.href = `https://admin.clairaudience.co.kr/api/engineer/v1/synesper-lite-family/measure/ppg/${heart_data.measureId}`;
        }
    };

    const date_time_cut = (data) => {
        const date = data.split('.')[0].split("T");

        return (
            <p>{date[0]}<br/>{date[1]}</p>
        );
    }

    const reset_list = () =>{
        setHearts_data([]);
        let number = reset_count;
        setReset_count(number+1);
    }

    useEffect(() => {
        setonoff(false);

        const fetchData = async () => {
            let heartdata = await MeasureList_get(id, page_number-1);
            const box_name = await User_data_get(id);
            setName(box_name.userName);

            let new_hearts_graph = {};

            if (heartdata) {
                setHearts_data(heartdata.content); // data.content를 상태로 저장
                setTotalPages(heartdata.page.totalPages); // data.totalPages를 상태로 저장
            } else {
                console.log("데이터가 없습니다.");
            }

            return new_hearts_graph;
        };

        fetchData();

        /*
        const timer = setTimeout(() => {
            console.log(hearts_graph);
            setonoff(true);
        }, 1000); // 10000ms = 10초

        // 컴포넌트가 언마운트될 때 타이머를 정리합니다.
        return () => clearTimeout(timer);*/


    }, [now_page,reset_count,page_number]);

    useEffect(() => {

    }, [totalPages]);


    return (
        <div className={"user_list_page"}>
            <div className={"user_list_page_body"} style={back_color}>
                <div className={"graph2-contain"}>
                    <div className={"graph2-head"}>
                        <div>
                            {name}의 심박 그래프
                        </div>
                        <div>
                            <label>
                                <input
                                    type="radio"
                                    value="ON"
                                    checked={graphStatus === 'ON'}
                                    onChange={handleGraphStatusChange}
                                />
                                ON
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    value="OFF"
                                    checked={graphStatus === 'OFF'}
                                    onChange={handleGraphStatusChange}
                                />
                                OFF
                            </label>
                        </div>
                        <div>
                            <GrPowerReset
                                id={"F-measure-reset-button"}
                                onClick={()=>reset_list()} />
                        </div>
                    </div>
                    <div className={"graph2-body"}>
                        {
                            hearts_data.map((heart_data, index) => (

                                <div className={"graph2-item"} key={heart_data.measureId}>
                                    <div className={"graph2-item-id"}>
                                        <div style={{fontSize: "28px", fontWeight: "bold", paddingBottom: "30px"}}>
                                            {heart_data.measureId}
                                        </div>
                                        <div style={{fontSize: "15px", fontWeight: "bold"}}>
                                            {date_time_cut(heart_data.date)}
                                        </div>
                                    </div>
                                    {
                                        <div className={"graph2-item-graph"}>
                                            <F_Graph measureId={heart_data.measureId}/>
                                        </div>
                                    }
                                    <div className={"graph2-item-data"}>
                                        <div className={"graph2-item-data-avg"}>
                                            <div style={{fontSize: "15px", fontWeight: "bold"}}>평균</div>
                                            <div style={{fontSize: "40px", fontWeight: "bold"}}>{heart_data.mavg}</div>
                                        </div>
                                        <div className={"graph2-item-data-max"}>
                                            <div style={{fontSize: "15px", fontWeight: "bold"}}>최대</div>
                                            <div style={{fontSize: "40px", fontWeight: "bold"}}>{heart_data.mmax}</div>
                                        </div>
                                        <div className={"graph2-item-data-min"}>
                                            <div style={{fontSize: "15px", fontWeight: "bold"}}>최소</div>
                                            <div style={{fontSize: "40px", fontWeight: "bold"}}>{heart_data.mmin}</div>
                                        </div>
                                        <div className={"graph2-item-data-download"}>

                                            <div className="dropdown">
                                                <button className="dropdown-toggle" type="button"
                                                        data-bs-toggle="dropdown" aria-expanded="false">
                                                    Download
                                                </button>
                                                <ul className="dropdown-menu">
                                                    <li><a className="dropdown-item" onClick={(event) => {
                                                        handleClick_ppg(event, heart_data);
                                                    }}>PPG</a></li>
                                                    <li><a className="dropdown-item" onClick={(event) => {
                                                        handleClick_pcg(event, heart_data);
                                                    }}>PCG</a></li>
                                                </ul>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            ))}
                    </div>
                    <UserList_pagenation change_page={change_page} now_page={page_number-1} setNow_page={setNow_page} totalPages={totalPages}/>
                </div>
            </div>
        </div>
    );
};


/*
<div className={"graph_img_frame"}>
                            <div>
                                <div>
                                    measureId : {heart_data.measureId}
                                </div>
                            </div>
                            <div className={"graph"}>
                                {
                                    onoff ?
                                        hearts_graph[heart_data.measureId] != undefined ?
                                            <Line data={hearts_graph[heart_data.measureId].data}
                                                  options={hearts_graph[heart_data.measureId].options}/>
                                            :
                                            <Line data={chart_data.data} options={chart_data.options}/>
                                        :
                                        <Line data={chart_data.data} options={chart_data.options}/>

                                }

                            </div>
                        </div>
                        <div className={"graph_data_frame"}>
                            <table>
                                <thead>
                                <tr>
                                    <th>
                                        <p className={"test_font"}>평균</p>
                                        <p className={"test_font_a"}>{heart_data.mavg}</p>
                                    </th>
                                    <th><p className={"test_font"}>최대</p>
                                        <p className={"test_font_a"}>{heart_data.mmax}</p>
                                    </th>
                                    <th>
                                        <p className={"test_font"}>최소</p>
                                        <p className={"test_font_a"}>{heart_data.mmin}</p>
                                    </th>
                                </tr>
                                </thead>
                            </table>
                            <div id={"aaa"}>
                                {heart_data.date}
                            </div>
                            <div className={"family-graph_download_frame"}>

                                <Dropdown>
                                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                                        Dropdown Button
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={(event) => {
                                            handleClick_pcg(event, heart_data);


}}>pcg DownLoad</Dropdown.Item>
<Dropdown.Item
    onClick={(event) => {
        handleClick_ppg(event, heart_data);
    }}
>ppg DownLoad</Dropdown.Item>
</Dropdown.Menu>
</Dropdown>
</div>
</div>










<ul className="menu align-center expanded text-center SMN_effect-103">
                                    <li><a
                                        href={`https://admin.clairaudience.co.kr/api/engineer/v1/synesper-lite-family/measure/pcg/${heart_data.measureId}`}
                                        onClick={async (event) => {
                                            handleClick_pcg(event, heart_data);


                                            const data = await Download_pcg_get(heart_data.measureId);
                                            const byte_data = await streamToByteArray(data);
                                            const sampleWidth = 2; // 16비트 고정 (하드코딩)
                                            const floatArray = byteArrayToFloatArray(byte_data, sampleWidth);
                                            let data1 = await Download_ppg_get(heart_data.measureId);
                                            const test_data = Chart_Data(floatArray, data1);
                                            setChart_data(test_data);


}}>
<span><p>pcg</p></span></a></li>
<li><a
href={`https://admin.clairaudience.co.kr/api/engineer/v1/synesper-lite-family/measure/ppg/${heart_data.measureId}`}
onClick={(event) => {
    handleClick_ppg(event, heart_data);
}}
>
<span>
<p>ppg</p>
</span>
</a></li>
</ul>
 */


/*

                                 const data = await Download_pcg_get(heart_data.measureId);
                                 const byte_data = await streamToByteArray(data);
                                 const sampleWidth = 2; // 16비트 고정 (하드코딩)
                                 const floatArray = byteArrayToFloatArray(byte_data, sampleWidth);
                                 let data1 = await Download_ppg_get(heart_data.measureId);
                                 const test_data = Chart_Data(floatArray, data1);
                                 setChart_data(test_data);

*/


export default F_Measure_page;