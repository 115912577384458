
function extentionArray(PCGFloatArray,PPGOriginalArray,measureId) {


    //PCG의 데이터수/PPG의 데이터 수
    const repeatCount = Math.round(Object.keys(PCGFloatArray).length/Object.keys(PPGOriginalArray).length);

    const PPGRepeatArray =  repeatElements(PPGOriginalArray,repeatCount);

    const sr =  16000;
    const lowcut = 30;
    const highcut = 500;
    const filterOrder = 101; // 필터의 길이(탭 수)
    //const PCGPassedArray = BandPassFilter(PCGFloatArray,sr,lowcut,highcut);

    //console.log(PCGPassedArray)

    const bandpassCoeffs = generateBandpassCoefficients(sr, lowcut, highcut, filterOrder);

// 필터 적용
    const PCGfilteredArray = applyFIRFilter(PCGFloatArray, bandpassCoeffs);


//시작지점 = 데이터의 10초 뒤 지점(10*16000)
    const startPoint = 160000;
    const showPeriod = 160000;


    const CutPPG = PPGRepeatArray.slice(startPoint,startPoint + showPeriod);
    const CutPCG = PCGfilteredArray.slice(startPoint,startPoint + showPeriod);


    const nomalP = MinMaxNomalization(CutPPG);
    const nomalC = MinMaxNomalization(CutPCG);


//데이터 반복 함수
    function repeatElements(array, repeatCount){
        const result = [];
        for (const item of array) {
            result.push(...Array(repeatCount).fill(item));
        }

        return result;
    }

    function MinMaxNomalization(array){
        const min = array.reduce((a, b) => Math.min(a, b));
        const max = array.reduce((a, b) => Math.max(a, b));

        if(min===0&&max===0)
        {
            return array.map(x => 0);
        }

        return array.map(x => 2 * ((x - min) / (max - min)) - 1)
    }



    const testP = extractEveryTenthNumber(nomalP)
    const testC = extractEveryTenthNumber(nomalC)

    return {
        "PPG" : testP,
        "PCG" : testC,
    };
}


///////////////////////////////////////////////////////////////////////////////////////////
function extractEveryTenthNumber (numbers) {
    return numbers.filter((_, index) => (index + 1) % 10 === 0);
}

function generateBandpassCoefficients(sampleRate, lowFreq, highFreq, filterOrder) {
    const nyquist = sampleRate / 2;
    const low = lowFreq / nyquist;
    const high = highFreq / nyquist;

    const coeffs = new Float32Array(filterOrder + 1);

    for (let i = 0; i <= filterOrder; i++) {
        if (i === filterOrder / 2) {
            coeffs[i] = 2 * (high - low);
        } else {
            const k = i - filterOrder / 2;
            coeffs[i] = (Math.sin(2 * Math.PI * high * k) - Math.sin(2 * Math.PI * low * k)) / (Math.PI * k);
        }
        // Apply Hanning window
        coeffs[i] *= 0.5 - 0.5 * Math.cos(2 * Math.PI * i / filterOrder);
    }

    return coeffs;
}

function applyFIRFilter(inputSignal, coeffs) {
    const outputSignal = new Float32Array(inputSignal.length);
    const filterOrder = coeffs.length;

    for (let n = 0; n < inputSignal.length; n++) {
        let acc = 0;
        for (let k = 0; k < filterOrder; k++) {
            if (n - k >= 0) {
                acc += coeffs[k] * inputSignal[n - k];
            }
        }
        outputSignal[n] = acc;
    }

    return outputSignal;
}

function Chart_Data(PCGFloatArray,PPGOriginalArray,measureId) {

    let size = 50;

    let data_box = {
        "PPG" : PCGFloatArray,
        "PCG" : PPGOriginalArray,
    };

    if(PCGFloatArray.length > 10 )
    {
        data_box = extentionArray(PCGFloatArray,PPGOriginalArray,measureId);
        size = 16000;
    }



    const labels = Array.from({ length: size }, (_, i) => (i + 1)/1600);
    const formattedNumbers = labels.map(number => number.toFixed(1));



    const data = {
        labels: formattedNumbers,
        datasets: [
            {
                label: 'PCG',
                data: data_box.PCG,
                borderColor: 'blue',
                backgroundColor: 'rgba(153, 102, 255, 0.2)',
                borderWidth: 0.5,
                yAxisID: 'y1',
                pointRadius: 0,
            },
            {
                label: 'PPG',
                data: data_box.PPG,
                borderColor: 'red',
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                borderWidth: 1.0,
                yAxisID: 'y1',
                pointRadius: 0,
            }

        ]
    };

    console.time("Chart Render");

    const options = {
        maintainAspectRatio: false,
        animation: false,
        plugins: {
            legend: {
                display: false, // 범례 숨기기
            },
            title: {
                display: false, // 제목 숨기기
            },
            tooltip: {
                enabled: false, // 툴팁은 숨기기
            },
        },
        scales: {
            y1 : {
                display: false, // y축 숨기기
            },
        },
    };

    const chart_data = {
        "data" : data,
        "options" : options,
    }

    return chart_data;
}

export default Chart_Data;

