import {useRef, useState} from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import {Form} from "react-bootstrap";
import {useOutletContext} from "react-router";


const F_User_delete = ({Delete,setDelete,udlc}) => {

    const modalBackground = useRef();
    const [check, setCheck] = useState('');


    return (
        <div>
            {
                Delete &&
                <div className={'modal-delete-container'} ref={modalBackground} onClick={e => {
                    if (e.target === modalBackground.current) {
                        setDelete(false);
                    }
                }}>
                    <div className={'modal-delete-content'}>
                        <div
                            className="modal show"
                            style={{display: 'block', position: 'initial'}}
                        >
                            <Modal.Dialog>
                                <Modal.Header closeButton onHide={()=>{setDelete(false)}}>
                                    <Modal.Title>Good Bye</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Form>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label className={"test"}>"삭제하기"를 입력하세요</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder='삭제하기'
                                                autoFocus
                                                value={check}
                                                onChange={(e) => setCheck(e.target.value)}
                                            />
                                        </Form.Group>
                                    </Form>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="outline-danger" onClick={() => {
                                        if (check === "삭제하기") {
                                            alert("일단 이거");
                                            udlc();
                                        } else {
                                            alert("입력이 잘못 되었습니다.");
                                        }
                                    }}>
                                        확인
                                    </Button>
                                </Modal.Footer>
                            </Modal.Dialog>
                        </div>
                    </div>
                </div>
            }
        </div>
    );

    /*
        <div>유저 삭제</div>
        <div>"삭제하기"를 입력해 주세요.</div>
        <input type="text" value={check} name="userId" placeholder="아이디" onChange={(e) => setCheck(e.target.value)}/>

        <button className={'modal-delete-close-btn'} onClick={() => {
            if (check === "삭제하기") {
                alert("일단 이거");
                udlc();
            } else {
                alert("입력이 잘못 되었습니다.");
            }
        }}>
            삭제하기
        </button>
     */
};

export default F_User_delete;